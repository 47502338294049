import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";
import { Formik } from "formik";

import TextInput from "../../components/Form/Input/Text";
import { SEO } from "../../components/SEO";
import { BodyTextM, HeadingL } from "../../components/Typography";
import cs from "../../translations/cs.json";

function ChangeEmailPage(): JSX.Element {
	const isDesktop = useIsDesktop();
	return (
		<>
			<Formik initialValues={{ email: [] }}>
				<Stack
					spacing={5}
					sx={{
						p: { md: 5 },
						boxShadow: (theme) => ({
							md: theme.customShadows.close,
						}),
						backgroundColor: "white.main",
					}}
				>
					<Stack spacing={5}>
						<Stack>
							<HeadingL mb={3}>
								{cs.changeEmailWithVerification.heading}
							</HeadingL>
							<BodyTextM>
								{cs.changeEmailWithVerification.description}
							</BodyTextM>
						</Stack>
						<Stack spacing={3}>
							<TextInput
								name={"email"}
								disabled={false}
								label={cs.global.email}
								sublabel={cs.changeEmailWithVerification.confirmCode}
								placeholder={cs.changeEmailWithVerification.yourEmail}
							/>
							{isDesktop ? (
								<LoadingButton
									variant="contained"
									sx={{ width: "40%", py: 3, px: 2 }}
									size="small"
								>
									{cs.changeEmailWithVerification.newCode}
								</LoadingButton>
							) : (
								<LoadingButton
									variant="contained"
									sx={{ width: "100%", py: 3, px: 2 }}
								>
									{cs.changeEmailWithVerification.newCode}
								</LoadingButton>
							)}
						</Stack>
						{isDesktop ? (
							<Stack
								sx={{ flexDirection: "row", justifyContent: "space-between" }}
							>
								<LoadingButton
									variant="outlined"
									size={"small"}
									sx={{ width: "47%" }}
								>
									{cs.changeEmailWithVerification.abort}
								</LoadingButton>
								<LoadingButton
									variant="contained"
									size={"small"}
									sx={{ width: "47%" }}
								>
									{cs.changeEmailWithVerification.mailConfirm}
								</LoadingButton>
							</Stack>
						) : (
							<Stack
								sx={{
									flexDirection: "column",
									justifyContent: "space-between",
								}}
								spacing={4}
							>
								<LoadingButton variant="outlined">
									{cs.changeEmailWithVerification.abort}
								</LoadingButton>
								<LoadingButton variant="contained">
									{cs.changeEmailWithVerification.mailConfirm}
								</LoadingButton>
							</Stack>
						)}
					</Stack>
				</Stack>
			</Formik>
		</>
	);
}

export default ChangeEmailPage;

export const Head = (): JSX.Element => (
	<SEO title={cs.changeEmailWithVerification.heading} />
);
